import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { pb, pt, px, Margin } from 'styled-components-spacing';

import Card from '@nib/card';
import { Stack, Box, Inline, Tiles, Section } from '@nib/layout';

import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import Container from '@nib-components/container';
import colors from '@nib-components/colors';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import Theme, { nib, linkLoud } from '@nib-components/theme';

import NotificationBanner from '../components/NotficationBanner/NotificationBanner';
import CallBackModal from '../components/CallBackModal';
import HeroPanel from '../components/HeroPanel';
import Layout from '../components/Layout';
import WhyChooseNibSection from '../components/shared/WhyChooseNib';
import UtilityButtons from '../components/UtilityButtons';
import { urlConstants } from '../constructs/constants';
import { UtilityLinkModel } from '../constructs/models';
import { getFormattedDateFromDateString } from '../utils/moment-utils';

import metrics from '../metrics';
import Consultation600w from '../img/home/consultation600x560.jpg';
import Consultation343w from '../img/home/consultation343x200.jpg';
import Claims600w from '../img/home/claims-app600x560.png';
import AppStoreBadge from '../img/badge_app_store.svg';
import GooglePlayBadge from '../img/badge_google_play.svg';
import { HeroPanelCopy } from '../components/styledComponents';
import useCampaign from '../hooks/useCampaign';

export const homePageQuery = graphql`
  query HomePageQuery {
    allContentfulBlogArticle(sort: { fields: startDate, order: DESC }, limit: 3) {
      edges {
        node {
          title
          urlSlug
          startDate
          heroType
          summary {
            summary
          }
          thumbnail {
            title
            file {
              url
            }
          }
          heroImage {
            title
            file {
              url
            }
          }
        }
      }
    }
    allContentfulHomePage(sort: { fields: startDate, order: DESC }, limit: 2) {
      edges {
        node {
          heroTitle
          heroCopy {
            childMarkdownRemark {
              html
            }
          }
          heroImages {
            desktop {
              file {
                url
              }
            }
            desktop2x4000x1200 {
              file {
                url
              }
            }
            mobile2x800x300 {
              file {
                url
              }
            }
            mobile400x150 {
              file {
                url
              }
            }
            tablet2x1600x580 {
              file {
                url
              }
            }
            tablet800x290 {
              file {
                url
              }
            }
          }
          legal {
            childMarkdownRemark {
              html
            }
          }
          campaignCode
          startDate
        }
      }
    }
  }
`;

interface FileShape {
  file: {
    url: string;
  };
}

interface HeaderSectionProps {
  title: string;
  copy: {
    childMarkdownRemark: {
      html: string;
    };
  };
  images: {
    desktop: FileShape;
    desktop2x4000x1200: FileShape;
    mobile2x800x300: FileShape;
    mobile400x150: FileShape;
    tablet2x1600x580: FileShape;
    tablet800x290: FileShape;
  };
  legal: {
    childMarkdownRemark: {
      html: string;
    };
  };
}

const StyledLegalCopy = styled.div`
  a {
    color: ${linkLoud};
  }
}`;

const HeaderSection = ({ title, copy, images, legal }: HeaderSectionProps): JSX.Element => (
  <HeroPanel
    title={title}
    variation="condensed"
    images={{
      desktop: images?.desktop?.file?.url,
      desktop2x: images?.desktop2x4000x1200?.file?.url,
      mobile: images?.mobile400x150?.file?.url,
      mobile2x: images?.mobile2x800x300?.file?.url,
      tablet: images?.tablet800x290?.file?.url,
      tablet2x: images?.tablet2x1600x580?.file?.url,
    }}
  >
    <HeroPanelCopy dangerouslySetInnerHTML={{ __html: copy?.childMarkdownRemark?.html }} />
    <Inline space={{ xs: 2, md: 4 }}>
      <SecondaryButton href="/compare-plans">Compare plans</SecondaryButton>
      <PrimaryButton href={urlConstants.getQuickQuote()}>Get a quote</PrimaryButton>
    </Inline>
    <Copy>
      <StyledLegalCopy dangerouslySetInnerHTML={{ __html: legal?.childMarkdownRemark?.html }} />
    </Copy>
  </HeroPanel>
);

const NavigationSection = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const utilityLinks: UtilityLinkModel[] = [
    {
      subtitle: 'NZ health insurance:',
      title: 'Learn how it works',
      url: '/health-insurance',
    },
    {
      subtitle: 'Need help joining?',
      title: 'Ask an expert',
      onClick: () => setIsOpen(true),
    },
    {
      subtitle: 'Making a claim?',
      title: 'Start your claim',
      url: urlConstants.mynib,
    },
    {
      subtitle: 'non-PHARMAC cover:',
      title: 'Learn more',
      url: urlConstants.nonPharmac,
    },
  ];
  return (
    <Section role="section" background="warmWhite60" padding={4}>
      <CallBackModal variant="Expert" isOpen={isOpen} setOpen={setIsOpen} />
      <UtilityButtons buttons={utilityLinks} flex={true} />
    </Section>
  );
};

const FindProviderGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gridItem1'
    'gridItem3'
    'gridItem2';
  ${breakpoint('lg')`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 200px;
    grid-template-areas:
      'gridItem1 gridItem3'
      'gridItem2 gridItem3'
  `};
`;

const FindProviderGridItem1 = styled.div`
  grid-area: gridItem1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${breakpoint('lg')`
    ${px(4)};
    ${pb(0)};
  `};
`;

const FindProviderGridItem2 = styled.div`
  grid-area: gridItem2;
  text-align: center;
  ${pt(4)};
  ${breakpoint('lg')`
    text-align: left;
    ${px(4)};
  `};
`;

const FindProviderGridItem3 = styled.div`
  grid-area: gridItem3;
  display: grid;
  ${breakpoint('lg')`
    min-height: 475px;
  `};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gridItem1'
    'gridItem3'
    'gridItem2';
  ${pb(6)};
  ${breakpoint('lg')`
    ${pb(8)};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 150px;
    grid-template-areas:
      'gridItem1 gridItem3'
      'gridItem2 gridItem3'
  `};
`;

const GridItem1 = styled.div`
  grid-area: gridItem1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${breakpoint('lg')`
    ${px(4)};
    ${pb(0)};
  `};
`;

const GridItem2 = styled.div`
  grid-area: gridItem2;
  text-align: center;
  ${pt(4)};
  ${breakpoint('lg')`
    text-align: left;
    ${px(4)};
  `};
`;

const GridItem3 = styled.div`
  grid-area: gridItem3;
  display: grid;
  ${breakpoint('lg')`
    min-height: 475px;
  `};
`;

const GridImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  text-align: center;

  ${breakpoint('lg')`
    height: 100%;
    width: auto;
    text-align: left;
  `};
`;

const GridImage = styled.img`
  height: auto;
  width: 100%;
  ${pt(4)};

  ${breakpoint('lg')`
    position: absolute;
    ${pt(0)};
    ${(props) =>
      !props.thin
        ? css`
            height: 100%;
            width: auto;
          `
        : css`
            width: 100%;
          `}
  `};
`;

const FindProviderSection = (): JSX.Element => (
  <Section role="section" background="sageGreen60">
    <FindProviderGrid>
      <FindProviderGridItem1>
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading
            align={{ xs: 'center', md: 'left' }}
            size={{ sm: 4, md: 3, lg: 2 }}
            component="h2"
            color="trueGreen"
          >
            Who can you see for treatment?
          </Heading>
          <Copy measure={false}>
            You can search our directory to find healthcare providers in your area. Find out if your
            healthcare provider is part of our First Choice network and whether they can take care
            of your paperwork, so you can focus on getting well.
          </Copy>
        </Stack>
      </FindProviderGridItem1>
      <FindProviderGridItem2>
        <PrimaryButton color="dark" href={urlConstants.findAProvider}>
          Find a Provider
        </PrimaryButton>
      </FindProviderGridItem2>
      <FindProviderGridItem3>
        <GridImageWrapper>
          <picture>
            <source media="(max-width:640px)" srcSet={`${Consultation343w}`} />
            <GridImage src={Consultation600w} alt="First Choice Network Directory"></GridImage>
          </picture>
        </GridImageWrapper>
      </FindProviderGridItem3>
    </FindProviderGrid>
  </Section>
);

const MobileAppSection = (): JSX.Element => {
  const AppStoreUrl = 'https://itunes.apple.com/us/app/my-nib-nz/id968982318?mt=8';
  const GooglePlayUrl = 'https://play.google.com/store/apps/details?id=nz.co.nib.mynib&amp;hl=en';
  return (
    <Section role="section">
      <Grid>
        <GridItem1>
          <Stack space={{ xs: 2, md: 4 }}>
            <Heading
              align={{ xs: 'center', md: 'left' }}
              size={{ sm: 4, md: 3, lg: 2 }}
              component="h3"
              color="trueGreen"
            >
              Manage your health cover easily with our app
            </Heading>
            <Copy measure={false}>
              Claiming with the nib app is as easy as taking a photo of your treatment receipt.
              Everyday claims are processed faster when you submit them through the app, so you can
              get your eligible costs refunded earlier.
            </Copy>
          </Stack>
        </GridItem1>
        <GridItem2>
          <Box textAlign="center">
            <Margin inline right={2}>
              <Link href={AppStoreUrl} target="_blank" underline={false}>
                <img src={AppStoreBadge} height="40" alt="App Store" title="App Store" />
              </Link>
            </Margin>
            <Link href={GooglePlayUrl} target="_blank" underline={false}>
              <img src={GooglePlayBadge} height="40" alt="Google Play" title="Google Play" />
            </Link>
          </Box>
        </GridItem2>
        <GridItem3>
          <GridImageWrapper>
            <GridImage
              src={Claims600w}
              alt="Manage your health insurance cover with the nib app"
            ></GridImage>
          </GridImageWrapper>
        </GridItem3>
      </Grid>
    </Section>
  );
};

const BackgroundImg = styled.img`
  height: 275px;
  width: 100%;
  background-image: ${(props) => `url('${props.backgroundImage}')`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${colors.sneezy};

  ${breakpoint('lg')`
    height: 200px;
  `};

  ${breakpoint('xl')`
    height: 250px;
  `};

  ${breakpoint('xxl')`
    height: 300px;
  `};
`;

interface ContentfulBlogArticleSummary {
  summary: string;
}

interface LinkShape {
  title: string;
  file: {
    url: string;
  };
}

interface ContentfulBlogArticleNode {
  title: string;
  urlSlug: string;
  startDate: string;
  summary: ContentfulBlogArticleSummary;
  thumbnail: LinkShape;
  heroImage: LinkShape | null;
}

interface NewsCardProps {
  cardDetails: ContentfulBlogArticleNode;
}

const NewsCard = (props: NewsCardProps): JSX.Element => {
  const { cardDetails } = props;

  const { thumbnail, startDate, summary, title, urlSlug } = cardDetails;

  // TODO: Change the Card component so that the `Read more` links are
  // inline with the adjacent cards
  return (
    <Card
      withBoxShadow={true}
      height="100%"
      image={<BackgroundImg backgroundImage={thumbnail?.file?.url} />}
      imageAlt={thumbnail?.title}
    >
      <Card.Content title={title} titleComponent="div">
        <Stack space={4}>
          <Stack space={1}>
            <Copy small={true} color={colors.grumpy}>
              {getFormattedDateFromDateString(startDate)}
            </Copy>
            <Copy>{summary.summary}</Copy>
          </Stack>
          <Link href={`/free-resources/article/${urlSlug}`}>Read more</Link>
        </Stack>
      </Card.Content>
    </Card>
  );
};

interface ContentfulBlogArticleEdgesShape {
  node: ContentfulBlogArticleNode;
}

interface NewsListProps {
  articles: ContentfulBlogArticleEdgesShape[];
}

const NewsList = (props: NewsListProps): JSX.Element | null => {
  const { articles } = props;

  if (!articles.length) {
    return null;
  }

  return (
    <Stack space={4}>
      {/* TODO: Change the tiles component to support flexible cards */}
      <Tiles space={4} columns={{ sm: 1, md: 2, lg: 3 }}>
        {articles.map((article, index) => (
          <NewsCard cardDetails={article.node} key={index} />
        ))}
      </Tiles>
    </Stack>
  );
};

interface ContentfulBlogArticle {
  edges: ContentfulBlogArticleEdgesShape[];
}

interface ArticleSectionProps {
  articles: ContentfulBlogArticle;
}

const ArticlesSection = (props: ArticleSectionProps): JSX.Element => {
  const { articles } = props;
  return (
    <Container>
      <Stack space={7}>
        <NewsList articles={articles.edges} />
      </Stack>
    </Container>
  );
};

const HealthAndWellbeingSection = (props: ArticleSectionProps): JSX.Element => {
  const { articles } = props;
  return (
    <Section role="section" background="clearWhite">
      <Box textAlign="center" marginBottom={6}>
        <Heading size={{ sm: 4, md: 3, lg: 2 }} component="p" color="trueGreen">
          Health and Wellbeing
        </Heading>
      </Box>
      <ArticlesSection articles={articles} />
      <Box textAlign="center" marginTop={6}>
        <PrimaryButton href="/free-resources">See all articles</PrimaryButton>
      </Box>
    </Section>
  );
};

interface SpecialOfferNode {
  heroTitle: string;
  heroSubTitle: string;
  heroCopy: {
    childMarkdownRemark: {
      html: string;
    };
  };
  heroImages: {
    desktop: FileShape;
    desktop2x4000x1200: FileShape;
    mobile2x800x300: FileShape;
    mobile400x150: FileShape;
    tablet2x1600x580: FileShape;
    tablet800x290: FileShape;
  };
  legal: {
    childMarkdownRemark: {
      html: string;
    };
  };
  campaignCode: string;
  startDate: string;
}

interface ContentfulHomePage {
  edges: {
    node: SpecialOfferNode;
  }[];
}

interface HomePageGraphQl {
  allContentfulBlogArticle: ContentfulBlogArticle;
  allContentfulHomePage: ContentfulHomePage;
}

interface HomePageProps {
  data: HomePageGraphQl;
}

const title = 'NZ Health Insurance | Welcome to nib';
const description =
  'Looking for health insurance cover in New Zealand? Find out why over one million Kiwis & Aussies trust nib for their health insurance.';

const HomePage = (props: HomePageProps): JSX.Element => {
  const { data } = props;
  const campaignInfo = useCampaign(data.allContentfulHomePage?.edges.map((edge) => edge.node));

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': 'Organization',
    name: 'nib nz insurance limited',
    url: 'https://www.nib.co.nz',
    address: '48 Shortland Street, Auckland CBD, Auckland 1010',
    location: 'New Zealand',
    sameAs: 'https://www.facebook.com/nibNewZealand',
    telephone: '0800 123 642',
    areaServed: 'New Zealand',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <>
      <Theme theme={nib}>
        <NotificationBanner />
      </Theme>
      <Layout>
        {/*
          The empty div below is a placeholder fix for a routing issue in the artical pages for news and media.
          This is copied from https://github.com/nib-group/nibnz-firstchoice-landingpage/blob/master/src/pages/index.js#L163
        */}
        <div />
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <script type="application/ld+json">{JSONschema}</script>
        </Helmet>
        <div>
          {campaignInfo ? (
            <HeaderSection
              title={campaignInfo.heroTitle}
              copy={campaignInfo.heroCopy}
              images={campaignInfo.heroImages}
              legal={campaignInfo.legal}
            />
          ) : (
            <div style={{ minHeight: 500 }}></div>
          )}
        </div>
        <NavigationSection />
        <WhyChooseNibSection left="YourChoice" middle="EasyToUse" right="SupportTools" />
        <FindProviderSection />
        <MobileAppSection />
        <HealthAndWellbeingSection articles={data.allContentfulBlogArticle} />
      </Layout>
    </>
  );
};

export default metrics({ pageName: 'NZ Public Website Main Page' })(HomePage);
